import {
    Alpine,
    Livewire,
} from "../../vendor/livewire/livewire/dist/livewire.esm";
import Tooltip from "@ryangjchandler/alpine-tooltip";
import "./videojs";
import "./bootstrap";

Alpine.plugin(Tooltip);

Alpine.data("app", () => {
    return {
        atTop: false,
        selected: null,
        navMenuOpen: false,
        init() {
            this.setAtTop();
        },
        setAtTop() {
            this.atTop = window.scrollY < 20 ? false : true;
        },
        scrollToEl(El) {
            if (typeof El == "string") {
                El = document.querySelector(El);
            }
            El.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        },
        btnAction: (callback = () => {}) => {
            return {
                ["@click"]() {
                    callback();
                },
                ["@keyup.enter"]() {
                    callback();
                },
            };
        },
    };
});

Alpine.data("mobileMenu", () => {
    return {
        menuHeight: 0,
        initialWrapper: 0,
        init() {
            this.initialWrapper =
                this.$el.querySelector("div.app-menu").scrollHeight;
            this.$watch("navMenuOpen", (open) => {
                this.setMenuHeight();
            });
            this.$watch("selected", (value) => {
                this.$el.style.setProperty("height", "auto");
                let el = this.$el;
                Alpine.debounce(function (el) {
                    el.style.setProperty("height", el.scrollHeight + "px");
                }, 400)(el);
            });
        },
        setMenuHeight() {
            this.menuHeight = this.$el.scrollHeight;
        },
    };
});

Livewire.start();
